import {
  ComponentWrapper,
  Breakdown,
  Text,
  TextLink,
} from '@stokr/components-library'
import { Background } from '@stokr/components-library/dist/components/Background/Background'
import { Cover } from 'components/LearnMoreSection/LearnMoreItem.styles'
import React from 'react'
import styled from 'styled-components'
import rwd from 'styles/rwd'

const ArtWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  ${rwd.Medium`
    
   flex-direction:row;
    `}
`

const ArtTextWrapper = styled.div`
  width: 100%;

  ${rwd.Medium`
    
   width:30%;
    `}
`

const ArtworkComponent = ({ artSrc, artistName, title }) => {
  const handleArtInNewTabClick = () => {
    if (!artSrc) return
    window.open(`${artSrc}`, '_blank')
  }
  return (
    <>
      <ArtWrapper>
        <ArtTextWrapper>
          <Breakdown style={{ fontWeight: 500 }}>My artworks</Breakdown>
          <Text>
            <h5 style={{ margin: '16px 0' }}>KPMG artwork</h5>
          </Text>
          <TextLink
            style={{ textDecoration: 'underline' }}
            as="button"
            onClick={handleArtInNewTabClick}
          >
            Open art in new tab
          </TextLink>
        </ArtTextWrapper>
        <div style={{ width: '100%' }}>
          <Cover>
            <Background src={artSrc} investmentOpportunities />
          </Cover>
          <Text>
            {title && (
              <p
                style={{
                  marginBottom: 8,
                  //position: 'absolute',
                }}
              >
                {title}
              </p>
            )}
            {artistName && (
              <p
                style={{
                  marginTop: 8,
                  // position: 'absolute',
                  fontStyle: 'italic',
                }}
              >
                {artistName}
              </p>
            )}
          </Text>
        </div>
      </ArtWrapper>
    </>
  )
}

export default ArtworkComponent
