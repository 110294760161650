import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as EventDB from './EventDB'
import moment from 'moment'
import { indexOf } from 'lodash'
import { TransactionTypes } from 'constants/enums'
import { km_ify } from '@stokr/components-library/dist/utils/km_ify'

export const EventDBContext = React.createContext()

const daysAfterPendingTxGetDeleted = 14

export class EventDBProvider extends Component {
  state = {
    tokenBalances: null,
    dollarInEuro: null,
    totalInvestment: 0,
    investmentsList: null,
    tokenTransfers: [],
    projects: [],
    projectInvestedIn: [],
    finalizedTx: [],
    pendingTx: [],
    documents: [],
    investmentDocuments: [],
    isLoadingDocuments: true,
    btcPrice: {},
    hasArtwork: false,
    isLoadingData: true,
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.updateState()
    }
  }

  async updateState() {
    var projects = {},
      exchangeRates = {},
      btcPrice,
      btcPrice30DaysAgo
    var tokenBalances = null,
      priceChange = null,
      aqfNAVPrice = null
    var dollarInEuro = 0
    var documents = []

    try {
      projects = await EventDB.fetchProjects()

      tokenBalances = await EventDB.fetchTokenBalances()

      exchangeRates = await EventDB.fetchExchangeRates()
      dollarInEuro = 1 / exchangeRates.rates.USD
    } catch (error) {
      console.log('🚀 ~ error', error)
    }

    //set projects imeddiately
    this.setState({
      projects: projects.projects,
      dollarInEuro,
    })

    try {
      documents = await EventDB.fetchProjectDocuments()
    } catch (error) {
      console.error(error)
    }

    try {
      priceChange = await EventDB.fetchPriceChange()
      btcPrice = await EventDB.fetchBTCPrice()

      //used for market data calculation
      const monthAgo = new Date().setDate(new Date().getDate() - 30)
      btcPrice30DaysAgo = await EventDB.fetchBTCPrice({
        timestamp: new Date(monthAgo).toISOString(),
      })
    } catch (error) {
      console.log('🚀 ~ error:', error)
    }

    let totalInvestment = 0
    let tokenBalanceFinal = []

    //update token Balances
    if (tokenBalances && tokenBalances.length > 0) {
      //check if any of the token balance is null and remove it
      tokenBalances = tokenBalances.filter((x) => x !== null && x !== undefined)
      tokenBalances.forEach(async (tokenAddress) => {
        const balance = tokenAddress.balance

        //find the project from assetid
        let project =
          projects &&
          projects.projects.find(
            (x) =>
              x.primaryAssetId === tokenAddress.asset ||
              x.secondaryAssetId === tokenAddress.asset,
          )
        if (project) {
          totalInvestment +=
            project.tokenCurrency === 'USD'
              ? balance * dollarInEuro * Number(project.tokenPrice)
              : balance * Number(project.tokenPrice)

          let tokenPriceInEURorUSD =
            project.tokenCurrency === 'USD'
              ? Number(project.tokenPrice) * dollarInEuro
              : Number(project.tokenPrice)

          let marketData = priceChange?.find(
            (x) => x.assetId === project.secondaryAssetId,
          )

          //for AQF, we call different EP for price change, only call it when user has AQF tokens
          if (project.name === 'aquarius') {
            try {
              aqfNAVPrice = await EventDB.fetchAQFPriceChange({
                assetId: project.secondaryAssetId,
              })
              marketData = {
                changePercentage: aqfNAVPrice.change30daysInPercent?.toFixed(2),
                ...aqfNAVPrice,
              }
            } catch (error) {
              console.log('🚀 error while fetching aqf NAV price:', error)
            }
          }

          if (marketData) {
            marketData = calculateMarketData(
              marketData,
              project,
              btcPrice,
              btcPrice30DaysAgo,
            )
          }

          tokenBalanceFinal.push({
            tokenName: project.tokenName,
            tokenSymbol: project.tokenSymbol,
            tokenCurrency: project.tokenCurrency,
            tokenDecimals: project.tokenDecimals,
            tokenPrice: project.tokenPrice,
            tokenPriceInEURorUSD,
            issuanceType: project.token_issuance_type,
            blockhainLink: this.getBlockchainLink(project.token_issuance_type),
            balance: tokenAddress.balance,
            asset:
              project.token_issuance_type === 'liquid'
                ? project.secondaryAssetId
                : tokenAddress.asset,
            account: tokenAddress.account,
            secondaryAssetId: project.secondaryAssetId,
            marketData,
          })
        }
      })
    }

    //update investment lists

    const {
      finalized,
      pending,
      tokenTransfers,
      projectInvestedIn,
      investmentDocs,
      hasArtworkAvailable,
    } = await this.getTokenTransfers(projects?.projects, documents)

    this.setState((prevState) => ({
      ...prevState,
      tokenBalances: tokenBalanceFinal,
      totalInvestment,
      projectInvestedIn,
      documents,
      tokenTransfers,
      investmentDocuments: investmentDocs,
      finalizedTx: finalized,
      pendingTx: pending,
      isLoadingDocuments: false,
      isLoadingData: false,
      hasArtwork: hasArtworkAvailable,
    }))
  }

  getTokenTransfers = async (
    projects = this.state.projects,
    documents = this.state.documents,
    updateState = false,
  ) => {
    const { user, checkTokenIsValid } = this.props

    var tokenTransfers = [],
      investmentDocs = [],
      projectInvestedIn = []
    const finalized = []
    const pending = []
    let hasArtworkAvailable = false

    if (user && checkTokenIsValid()) {
      try {
        tokenTransfers = await EventDB.fetchTokenTransfers()
      } catch (error) {
        console.log('🚀 ~ error getting investments', error)
      }
    }

    //we filter first so that any tx that passses gets the documents;
    //we don't want documents for old pendng tx
    tokenTransfers = tokenTransfers?.filter((transfer) => {
      const { investment } = transfer

      // If there's no investment, it is secondary market tx
      if (!investment) {
        return true
      }

      const { fulfilled, isRedemption, paidOut, createdAt } = investment
      const isFulFilled = isRedemption ? paidOut : fulfilled

      // If the investment is not fulfilled, check if it's older than the specified limit
      if (!isFulFilled) {
        const oldTxLimitDays = new Date(
          new Date().setDate(
            new Date().getDate() - daysAfterPendingTxGetDeleted,
          ),
        )
        const investmentCreation = new Date(createdAt)

        if (investmentCreation >= oldTxLimitDays) {
          return true
        }

        return false
      }

      return true
    })

    tokenTransfers &&
      tokenTransfers.forEach((transfer) => {
        const { tx, investment } = transfer

        let projectTx = projects?.find(
          (x) =>
            x.primaryAssetId === tx?.asset || x.secondaryAssetId === tx?.asset,
        )
        let investmentFiles = []
        const isSecondaryMarketTx = tx?.type === TransactionTypes.TRANSFER
        const isRedemptionTx = tx?.type === TransactionTypes.REDEMPTION

        if (investment || isSecondaryMarketTx) {
          if (investment)
            projectTx = projects?.find((x) => x._id === investment.project)

          if (documents && documents.length > 0 && projectTx) {
            //make a copy of documents without passing a reference
            //so we can manipulate file object without affecting in in the next loop
            const copyOfDocuments = JSON.parse(JSON.stringify(documents))

            investmentFiles = filterAndSortDocuments(
              copyOfDocuments,
              projectTx,
              investment,
              user,
              investmentDocs,
              isSecondaryMarketTx,
            )

            if (investmentFiles?.length > 0) {
              investmentDocs = [...investmentDocs, ...investmentFiles]
            }
          }
        }

        //add the project to invested projects array if not already there
        //and if there are investment docs for that project
        if (
          projectInvestedIn?.indexOf(projectTx) === -1 &&
          investmentFiles.length > 0
        ) {
          projectInvestedIn.push(projectTx)
        }

        const data = {
          ...investment,
          ...tx,
          files: investmentFiles,
          issuanceType: projectTx && projectTx.token_issuance_type,
          blockhainLink:
            projectTx && this.getBlockchainLink(projectTx.token_issuance_type),
          tokenDecimals: projectTx?.tokenDecimals,
        }

        if (investment) {
          const { fulfilled, isRedemption, paidOut } = investment

          const isFulFilled = isRedemption ? paidOut : fulfilled

          if (isFulFilled) {
            finalized.push(data)

            //for KPMG; show art when user has finalized transaction
            if (projectTx.name === 'techforgood') {
              hasArtworkAvailable = true
            }
          } else {
            pending.push(data)
          }
        }
        //ignore redemption tx without investment
        else if (!isRedemptionTx) {
          data.tokenSymbol = projectTx?.tokenSymbol
          finalized.push(data)
        }
      })

    if (updateState) {
      this.setState((prevState) => ({
        ...prevState,
        projectInvestedIn,
        tokenTransfers,
        investmentDocuments: investmentDocs,
        finalizedTx: finalized,
        pendingTx: pending,
        hasArtwork: hasArtworkAvailable,
      }))
    }

    return {
      finalized,
      pending,
      investmentDocs,
      projectInvestedIn,
      tokenTransfers,
      hasArtworkAvailable,
    }
  }

  storeInvestment = async (data) => {
    try {
      const result = await EventDB.storeInvestment(data)
      const { investment } = result

      this.setState((prevState) => ({
        ...prevState,
        pendingTx: [...prevState.pendingTx, investment],
      }))

      return result
    } catch (error) {
      throw error
    }
  }

  getBlockchainLink = (issuanceType) => {
    let blockhainLink = ''
    switch (issuanceType) {
      case 'liquid':
        blockhainLink = 'https://blockstream.info/liquid/asset/'
        break

      default:
        break
    }
    return blockhainLink
  }

  render() {
    const { children } = this.props
    const {
      tokenBalances,
      dollarInEuro,
      totalInvestment,
      investmentsList,
      projects,
      finalizedTx,
      pendingTx,
      documents,
    } = this.state

    return (
      <EventDBContext.Provider
        value={{
          finalizedTx,
          pendingTx,
          tokenBalances,
          dollarInEuro,
          totalInvestment,
          investmentsList,
          projects,
          documents,
          ...this.state,
          storeInvestment: this.storeInvestment,
          getTokenTransfers: this.getTokenTransfers,
        }}
      >
        {children}
      </EventDBContext.Provider>
    )
  }
}

EventDBProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
  userEthAddresses: PropTypes.array,
  userLiquidGaids: PropTypes.array,
}

EventDBProvider.defaultProps = {
  user: null,
  userEthAddresses: [],
  userLiquidGaids: [],
}

export const EventDBConsumer = EventDBContext.Consumer

const filterAndSortDocuments = (
  documents,
  project,
  investment,
  user,
  investmentDocs,
  isSecondaryMarket = false,
) => {
  const orderDocuments = [
    'investment-agreement',
    'redemption-confirmation',
    'redemption-agreement',
    'offering-terms',
    'terms-conditions',
    'supplement',
    'offering-document',
    'country-disclosure',
    'other',
  ]

  let filteredDocs = []

  if (isSecondaryMarket) {
    //filter documents for the secondary market condition and exclude any further processing
    filteredDocs = documents.filter((doc) => {
      return (
        doc.projectId === project._id &&
        doc.onlyInvestors &&
        !investmentDocs.some(
          (d) =>
            d.fileType === 'terms-conditions' &&
            d.onlyInvestors &&
            d.projectId === project._id,
        )
      )
    })
  } else {
    filteredDocs = documents
      .filter((doc) => doc.projectId === project._id) //filter project and investment documents
      .filter((doc) => !doc.templateId || doc.investmentId)
      .filter((doc) => {
        if (doc.investmentId) {
          return doc.investmentId === investment._id
        }
        if (doc.onlyInvestors) {
          return true
        }
        return investment?.isPrivateSale
          ? doc.isPrivateSaleDoc
          : doc.isPublicSaleDoc
      }) //filter private and public documents
      .filter((doc) => {
        if (doc.fileType === 'country-disclosure') {
          return doc.countries?.includes(user.country)
        }

        //for generic document (onlyInvestors flag), we show it once per project for all investors
        if (
          (doc.fileType === 'terms-conditions' ||
            doc.fileType === 'supplement') &&
          doc.onlyInvestors
        ) {
          return !investmentDocs.some(
            (doc) =>
              (doc.fileType === 'terms-conditions' ||
                doc.fileType === 'supplement') &&
              doc.onlyInvestors &&
              doc.projectId === project._id,
          )
        }

        if (
          (doc.fileType === 'supplement' ||
            doc.fileType === 'terms-conditions') &&
          project.type === 'tranches'
        ) {
          return doc.tranches?.includes(investment.sale)
        }

        return true
      })
  }

  //sort the documents by the sorting above
  const sortedDocs = filteredDocs.sort((a, b) => {
    if (a.onlyInvestors) return -1
    return (
      orderDocuments.indexOf(a.fileType) - orderDocuments.indexOf(b.fileType)
    )
  })

  //add missing fields to the document object
  sortedDocs.forEach((file) => {
    if (file.onlyInvestors) return

    file.investmentCreatedAt = investment.createdAt
    if (!file.templateId || file.investmentId === investment._id) {
      const dateToAdd = `_${moment(investment.createdAt).format('DD_MM_YYYY')}`

      file.fileName = `${file.fileName.slice(
        0,
        indexOf(file.fileName, '.'),
      )}${dateToAdd}.pdf`
    }
  })

  return sortedDocs
}

const calculateMarketData = (
  marketData,
  project,
  btcPrice,
  btcPrice30DaysAgo,
) => {
  const { assetIndexPrice, tokenCurrency } = project
  const { lastPrice, firstPrice } = marketData

  let last30DaysPriceChange = lastPrice - firstPrice
  let priceChangeIndexed
  let isPositiveMarketData =
    Number(marketData?.changePercentage) > 0
      ? true
      : Number(marketData?.changePercentage) < 0
      ? false
      : null

  let lastPriceCalculated
  //if assetIndexPrice ==BTC, it means we will display data in BTC value, othervise in project currency
  //bc sideswap gives market data in btc values, we will have to convert that price to USD or EUR

  //all values need to be multiplied with total tokens later
  if (assetIndexPrice === 'BTC') {
    priceChangeIndexed = last30DaysPriceChange
  } else if (assetIndexPrice === 'NAV') {
    priceChangeIndexed = marketData.lastPrice * marketData.changePercentage //multiply by total number of tokens
    lastPriceCalculated = marketData.lastPrice
  } else {
    let BTCPriceInEurOrUSD =
      tokenCurrency === 'USD' ? btcPrice?.USD : btcPrice?.EUR
    let BTCPriceInEurOrUSD30DaysAgo =
      tokenCurrency === 'USD' ? btcPrice30DaysAgo?.USD : btcPrice30DaysAgo?.EUR

    priceChangeIndexed = last30DaysPriceChange * BTCPriceInEurOrUSD

    //take into account BTC price change from 30 days as well
    marketData.changePercentage = (
      ((lastPrice * BTCPriceInEurOrUSD) /
        (firstPrice * BTCPriceInEurOrUSD30DaysAgo) -
        1) *
      100
    ).toFixed(2)
    lastPriceCalculated = marketData.lastPrice * BTCPriceInEurOrUSD30DaysAgo
  }

  const sideSwapUrl = `https://sideswap.io/swap-market/?product=${project?.tokenSymbol}`

  return {
    //info: marketInfo,
    sideSwapUrl,
    last30DaysPriceChange,
    isBTCIndex: assetIndexPrice === 'BTC',
    priceChangeIndexed,
    lastPriceCalculated,
    isPositive: isPositiveMarketData,
    ...marketData,
  }
}

//this function is used to get the increased/descreased value of your total tokens
//It needed to be a function bc the numberOf tokens depends on which address you choose
export const getMarketInfoString = (
  numberOfTokens,
  marketData,
  tokenCurrency,
) => {
  const { isBTCIndex, isPositive, priceChangeIndexed } = marketData
  const kmify = (child) => (isBTCIndex ? child : km_ify(child))

  return marketData?.last30DaysPriceChange !== 0
    ? `${isPositive ? '+' : isPositive === false ? '-' : ''} ${
        isBTCIndex ? '₿' : tokenCurrency === 'USD' ? '$' : '€'
      }${kmify(
        Math.abs(priceChangeIndexed * numberOfTokens).toFixed(
          isBTCIndex ? 8 : 2,
        ),
      )} (30d change)`
    : 'No 30d change '
}
