export const ProjectTypes = {
  FUND: 'fund',
  COMPANY: 'company',
  TRANCHES: 'tranches',
}

export const ProjectStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ON_HOLD: 'On_hold',
}

export const ProjectStates = {
  ON_HOLD: 'ON HOLD',
  LIVE: 'LIVE',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  CLOSED_SUCCESSFULLY: 'CLOSED SUCCESSFULLY',
  UPCOMING: 'UPCOMING',
}

export const TransactionTypes = {
  RECOVERY: 'Recovery',
  NOTE_SPLIT: 'Note Split',
  TRANSFER: 'Transfer',
  ISSUANCE: 'Issuance',
  REDEMPTION: 'Redemption',
}

export const ProfessionalInvestorTypes = {
  PROFESSIONAL: 'professional',
  NOT_PROFESSIONAL: 'not professional',
  NOT_CLASSIFIED: 'not classified',
}

export const USInvestorAcreditationStatuses = {
  US_ACCREDITED: 'US accredited',
  NOT_US_ACCREDITED: 'not US accredited',
  NOT_CLASIFIED: 'not classified',
}

export const DocumentTypes = {
  ID_CARD: 'ID CARD',
  PASSPORT: 'PASSPORT',
  DRIVERS: 'DRIVERS',
  RESIDENCE_PERMIT: 'RESIDENCE PERMIT',
  UTILITY_BILL: 'UTILITY BILL',
  PROFILE_IMAGE: 'PROFILE IMAGE',
  ID_DOC_PHOTO: 'ID DOC PHOTO',
  AGREEMENT: 'AGREEMENT',
  CONTRACT: 'CONTRACT',
  DRIVERS_TRANSLATION: 'DRIVERS TRANSLATION',
  INVESTOR_DOC: 'INVESTOR DOC',
  VEHICLE_REGISTRATION_CERTIFICATE: 'VEHICLE REGISTRATION CERTIFICATE',
  INCOME_SOURCE: 'INCOME SOURCE',
  PAYMENT_METHOD: 'PAYMENT METHOD',
  BANK_CARD: 'BANK CARD',
  OTHER: 'OTHER',
}
