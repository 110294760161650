import axios from 'axios'

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_URL_PUBLIC
    : 'https://stokr.io/api-gateway-no-auth/'

export default axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: BASE_URL,
})
