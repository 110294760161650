import React, { useEffect, useState } from 'react'
import axios, { post } from 'axios'
import { Modal, ModalInner } from 'components/Modal/Modal'
import { Button, ComponentWrapper, Text } from '@stokr/components-library'
import loader from 'static/images/process-waiting.gif'
import {
  FileInputWrapper,
  FileInput,
  FileInputRemove,
} from 'components/Settings/Settings.styles'
import Cookies from 'js-cookie'
import { Select } from '@stokr/components-library/dist/components/Input/Select'
import { DocumentTypes } from 'constants/enums'

const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024)

export const ToDoListModal = (props) => {
  const [file, setFile] = useState()
  const [docType, setdocType] = useState()
  const [selectDocBtnDisabled, setSelectDocBtnDisabled] = useState(true)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [successMsg, setSuccessMsg] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(false)
  const [fileSizeError, setfileSizeError] = useState(false)

  useEffect(() => {
    if (docType?.value) {
      setSelectDocBtnDisabled(false)
    }
  }, [docType])

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return
    }

    let file = e.target.files[0]

    if (bytesToMegaBytes(file.size) > 6) {
      setfileSizeError(true)
    } else {
      fileSizeError && setfileSizeError(false)
      setFile(file)
      setBtnDisabled(false)
    }
  }

  const removeFile = () => {
    setFile()
    setBtnDisabled(true)
  }
  const handleSubmit = async () => {
    setIsUploading(true)

    const data = new FormData()
    data.append('file', file)
    data.append('idDocType', docType?.value)

    try {
      const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')
      await axios.post(
        `${process.env.REACT_APP_API_URL}/sumsub/add-document`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      setIsUploading(false)
      setSuccessMsg(true)
    } catch (error) {
      setIsUploading(false)
      setError(true)
    }
  }

  const closeAndClearFile = () => {
    props.onModalClose()
    removeFile()
    setError(false)
    setSuccessMsg(false)
  }

  return (
    <Modal isOpen={props.isModalOpen} onClose={closeAndClearFile}>
      <ModalInner>
        <Text>
          <h3>ADDITIONAL DOCUMENT</h3>
        </Text>
        {successMsg ? (
          <ComponentWrapper noPaddingLeft>
            <Text data-cy="successUploadingDocument">
              <p>
                Thank you for submitting the document. Compliance team will
                contact you if needed.
              </p>
            </Text>
          </ComponentWrapper>
        ) : error ? (
          <ComponentWrapper noPaddingLeft>
            <Text>
              <p>
                Something went wrong during the upload of your document. Please
                contact support@stokr.io
              </p>
            </Text>
          </ComponentWrapper>
        ) : (
          <>
            <Text
              style={{ marginTop: '1rem', fontSize: '14px', fontWeight: '100' }}
            >
              <p>
                Please upload additional document if asked by the compliance
                team.
              </p>
            </Text>
            <ComponentWrapper noPaddingLeft noPaddingBottom>
              <Select
                id="document-type"
                // name={activeAddress.label}
                value={docType?.value}
                options={Object.keys(DocumentTypes).map((type) => {
                  return { key: type, value: type, label: DocumentTypes[type] }
                })}
                label="Select document type"
                onChange={(e) => {
                  setdocType({
                    value: e.value,
                    label: e.label,
                  })
                }}
                menuHeight={200}
                darker
                search
                onBlur={(e) => {}}
              />
            </ComponentWrapper>
            <ComponentWrapper noPaddingLeft>
              {file && (
                <div style={{ margin: '3rem 0 2rem' }}>
                  <FileInputRemove
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      removeFile()
                    }}
                    disabled={isUploading}
                  />
                  <span>
                    {/* {fileName} */}
                    {file.name}
                  </span>
                </div>
              )}

              <FileInputWrapper
                center
                minWidth="188px"
                disabled={selectDocBtnDisabled || isUploading || !!file}
                style={{ marginTop: '2rem', marginRight: '1rem' }}
              >
                SELECT DOCUMENT
                <FileInput
                  name="stokr-misc"
                  accept="image/png,image/jpeg,application/pdf"
                  onChange={handleFileUpload}
                  multiple="true"
                  disabled={selectDocBtnDisabled || isUploading}
                />
              </FileInputWrapper>

              <Button
                onClick={handleSubmit}
                disabled={btnDisabled || isUploading}
                data-cy="submitDocument"
              >
                {isUploading ? 'Uploading' : 'Submit'}
              </Button>
              {isUploading && (
                <img
                  src={loader}
                  alt=""
                  style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
                />
              )}
              {fileSizeError && (
                <Text>
                  <p
                    style={{
                      marginTop: '1rem',
                      fontSize: '12px',
                      fontWeight: '100',
                      color: '#ee220d',
                    }}
                  >
                    Maximum allowed file size cannot exceed 6MB.
                  </p>
                </Text>
              )}
            </ComponentWrapper>
          </>
        )}
      </ModalInner>
    </Modal>
  )
}

export default ToDoListModal
