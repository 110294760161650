import styled from 'styled-components'
import rwd from 'styles/rwd'

export const LoaderImg = styled.img`
  width: 100%;
  ${rwd.Medium`
    width:auto;
  `}
`

export const Container = styled.div`
  position: relative;
  display: block;
`

export const Main = styled.div`
  position: relative;
  z-index: 1;
  display: block;

  ${rwd.Medium`
    display: flex;
    flex-direction: row;
  `}
`

export const AvatarBox = styled.div`
  width: 100%;

  ${rwd.Medium`
    width: 31%;
  `}
`

export const InfoBox = styled.div`
  width: 100%;

  ${rwd.Medium`
    width: 69%;
    display: flex;
    flex-direction: column;
  `}
`

export const InfoInner = styled.div`
  ${rwd.Medium`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  `}
`

export const Badges = styled.div`
  display: flex;
  flex-direction: row;

  ${rwd.Medium`
    flex-direction: column;
  `}

  ${rwd.Large`
    width: 27.6%;
  `}
`

export const Badge = styled.div`
  flex-grow: 1;
  display: flex;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};

  ${rwd.Medium`
    flex-grow: 1;
    height: 50%;
  `}

  ${(props) =>
    props.borderTop &&
    `
    border-top: 1px solid ${props.theme.cLightGrey};
  `};

  ${(props) =>
    props.borderBottom &&
    `
    border-bottom: 1px solid ${props.theme.cLightGrey};
  `};
`

export const BadgeImg = styled.img`
  height: 100px;

  @media screen and (max-width: 1023px) {
    height: 80px;
  }
`

export const Stats = styled.div`
  ${rwd.Medium`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `}
`

export const Stat = styled.div`
  flex-grow: 1;
  display: flex;

  ${rwd.Medium`
    flex-grow: 1;
    height: 50%;
  `}

  ${(props) =>
    props.borderTop &&
    `
    border-top: 1px solid ${props.theme.cPrimaryDark};
  `};

  ${(props) =>
    props.borderBottom &&
    `
    border-bottom: 1px solid ${props.theme.cPrimaryDark};
  `};
`

export const Togglers = styled.div`
  ${rwd.Medium`
    display: flex;
    flex-direction: row;
    border-right: 64px solid ${(props) => props.theme.cWarning};
  `}
`

export const Toggler = styled.div`
  ${rwd.Medium`
    flex-grow: 1;
    border-left: 1px solid ${(props) => props.theme.cLightGrey};
  `}
`

export const TabsContainer = styled.div`
  position: relative;
  z-index: 0;
  margin-top: -1px;
`
